<template>
  <v-navigation-drawer
    permanent
    right
    absolute
    width="250"
    class="pa-4 grey--text"
  >
    <v-layout>
      <v-divider vertical/>
      <v-layout column>
        <h3 class="display-1 mb-5">{{$t('summary.title')}}</h3>
        <template v-if="!error">
          <!-- TRANSACTIONS -->
          <v-layout v-for="adjustment in guests.adjustments" justify-space-between align-center class="flex-none mb-3" :key="adjustment.packageId">
            <span
              class="title"
              :class="{
                'error--text': adjustment.quantity < 0,
                'success--text': adjustment.quantity > 0
              }"
            >
              {{ localize({ array: getPackageField({ id: adjustment.packageId, field: 'name' }) || [] }) }}
            </span>
            <span class="show-spaces text-right">
              {{adjustment.quantity > 0 ? '+' : ''}}{{adjustment.quantity}}
            </span>
          </v-layout>
          <v-layout v-if="!guests.adjustments.length" wrap class="my-3 title font-weight-light flex-none">
            <v-flex xs12>
              {{$t('summary.empty_adjustments')}}
            </v-flex>
          </v-layout>
        </template>
        <!-- ERROR -->
        <v-layout v-else justify-end class="pt-4 mb-5 flex-none">
          <v-alert color="warning" dark>
            {{ $t('packages.errors.amount') }}
          </v-alert>
        </v-layout>
        <!-- WARNINGS -->
        <v-layout v-if="dailyLimitExceedingAdjustments.length" justify-end class="mt-3 flex-none">
          <v-alert color="warning" dark>
            {{ `${$t('packages.warnings.daily_limit_exceeding_packages')} ${dailyLimitExceedingAdjustments.map((a) => getAdjustmentName(a)).join(', ')}.` }}
          </v-alert>
        </v-layout>
        <v-layout v-if="accountLimitExceedingAdjustments.length" justify-end class="mt-3 flex-none">
          <v-alert color="warning" dark>
            {{ `${$t('packages.warnings.account_limit_exceeding_packages')} ${accountLimitExceedingAdjustments.map((a) => getAdjustmentName(a)).join(', ')}.` }}
          </v-alert>
        </v-layout>
        <!-- CONTINUE -->
        <v-layout wrap justify-end class="mt-5">
          <confirm-button
            :text="$t('summary.actions.proceed_with')"
            @confirm="$emit('accept')"
            :disabled="!guests.adjustments.length || error"
            :loading="state.loading"
          />
        </v-layout>
      </v-layout>
    </v-layout>
  </v-navigation-drawer>
</template>
<script>
import localizationMixins from '@/mixins/Localization'
import { getTokensInAccount } from '@/utils/GuestUtils'
import { mapState, mapGetters } from 'vuex'

export default {
  // EMITTED EVENTS: accept
  props: {
    extendedAccount: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['state', 'guests', 'packages']),
    ...mapGetters({
      getPackage: 'packages/getPackage',
      getPackageField: 'packages/getPackageField'
    }),
    error () {
      return !!Object.keys(this.guests.errors).length
    },
    dailyLimitExceedingAdjustments () {
      return this.guests.adjustments.filter((adjustment) => {
        const pkg = this.getPackage({ id: adjustment.packageId })
        return pkg && pkg.mustBeBooked && typeof pkg.currentAvailableProducts === 'number' &&
          pkg.currentAvailableProducts < adjustment.quantity
      })
    },
    accountLimitExceedingAdjustments () {
      return this.guests.adjustments.filter((adjustment) => {
        const pkg = this.getPackage({ id: adjustment.packageId })
        return pkg && pkg.maxTokenAmountOnAccount && pkg.maxTokenAmountOnAccount < (adjustment.quantity * pkg.tokenAmount + getTokensInAccount(this.extendedAccount.currencies, pkg))
      })
    }
  },
  methods: {
    getAdjustmentName (adjustment) {
      return this.localize({ array: this.getPackageField({ id: adjustment.packageId, field: 'name' }) })
    }
  },
  components: {
    ConfirmButton: () => import('@/components/app-components/buttons/ConfirmButton.vue')
  },
  mixins: [
    localizationMixins
  ]
}
</script>
